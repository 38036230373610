import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown/with-html'

import SEO from '@/components/SEO/Seo'
import Layout from '@/components/Layout'
import ContactForm from '@/components/Forms/Contact'
import Map from '@/components/Map'

export const ContactPageTemplate = ({ data }) => {
  return (
    <>
      <div className='full-height-section'>
        <div className='container py-16'>
          <div className='mb-16'>
            <div className='decorator'></div>
            <h1 className='text-center'>{data.title}</h1>
          </div>
          <div className='contact-blocks'>
            {data.blocks.map((item, i) => {
              return (
                <div className='contact-block' key={i}>
                  <ReactMarkdown
                    children={item}
                    allowDangerousHtml
                    className='contact-block__content'
                  />
                </div>
              )
            })}
          </div>

          {/* <div className='contact'>
            <div className='contact-form'>
              <div className='decorator mr-auto ml-0'></div>
              <h2>{data.leasing.title}</h2>
              <div className='contact-form__wrapper'>
                <ContactForm formTitle='Contact Form' />
              </div>
            </div>
          </div> */}

          <div className='leasing leasing--bottom'>
            <div className='leasing__content'>
              <div className='decorator mr-auto ml-0'></div>
              <h2>{data.general.title}</h2>
              <ReactMarkdown
                children={data.general.content}
                allowDangerousHtml
                className='text-grey-dark'
              />
            </div>
            <div className='leasing__form'>
              <div className='contact-form__wrapper'>
                <ContactForm formTitle='General Enquiries' />
              </div>
            </div>
          </div>

          <div className='contact-map'>
            <Map
              googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyALWhNZ4Pjlhqb2B831kc7m-g9D5kdaLRM&libraries=geometry,drawing,places`}
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={<div className='contact-map__wrapper' />}
              mapElement={<div style={{ height: `100%` }} />}
            />
          </div>
        </div>
      </div>
    </>
  )
}

const ContactPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <SEO data={frontmatter.seo} />
      <ContactPageTemplate data={frontmatter} />
    </Layout>
  )
}

ContactPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export const pageQuery = graphql`
  query ContactPageTemplate {
    markdownRemark(frontmatter: { template: { eq: "contact" } }) {
      frontmatter {
        title
        blocks
        leasing {
          title
          content
        }
        general {
          title
          content
        }
        ...SEO
      }
    }
  }
`

export default ContactPage
